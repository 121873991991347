<template>
	<div class="wrapper" v-show='viewShow'>
		<section class="wrapper_top"><img :src="require('@/assets/image/fh2.png')" alt="" @click="fh()" /> 取消預約</section>
		<section class="wrapper_centent">  
			<div class="box">
				<div class="titleValue">請輸入取消原因</div>
				<div class="textcent">
					<textarea placeholder="請輸入您的原因" v-model="textValue"> </textarea>
					<span>还可以输入{{this.titleMaxLength - this.textValue.length}}個字</span>
				</div>
				<!-- <div class="uls">
					<div class="lis">1.每個人只能取消<span style="color: red;">&nbsp;1&nbsp;</span>次;</div> 
				</div> -->
			</div> 
		</section> 
		
		<div class="fromBut" @click="fromBut">確定取消</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';  
import api from '@/api/index';
export default {
	components: {},
	name: 'cancelReservation',
	data() {
		return {
			renderData:{},
			textValue:'',
			titleMaxLength: 100
		};
	},
	watch: {
		textValue() {
			if (this.textValue.length > this.titleMaxLength) {
				this.textValue = String(this.textValue).slice(0, this.titleMaxLength);
			}
		}
	},
	components: {},
	created() {
		console.log(this.$route.query)
		this.renderData = JSON.parse(this.$route.query.list) 
	},
	mounted() {},
	computed: {
		 ...mapState(['isPc','viewShow']),
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	methods: {
		fh() {
			history.go(-1); //返回首页	 
		}, 
		fromBut() {
			if (!this.textValue || !this.textValue.trim()) {
				this.$toast('請輸入取消原因');
				return false;
			}
			
			this.$dialog
				.confirm({
					title: '提示',
					message: '取消不可复原,是否确定取消?'
				})
				.then(() => {
					// on confirm
					this.getCancelAppointments()
				})
				.catch(() => {
					// on cancel
				});  
		},
		getCancelAppointments(){
			this.$toast.loading({
				message: '提交中...',
				forbidClick: true,
				overlay: true,
				duration: 0
			})
			
			let data = {
				cancelReason: this.textValue,
				testCenterId: this.renderData.testCenterId 
			}
			
			api.cancelAppointments(data)
				.then(res => {
					this.$toast.clear();
			
					console.log(res);
					if (res.code == 0) {
						this.$toast('取消成功');
						let a = setTimeout(()=>{
							// 清除定时器
							clearTimeout(a)
							this.$router.push('/reservationInquireList'); 
						},1000);
					}else{
						this.$toast(res.msg)
					}
				})
				.catch(error => {
					this.$toast.clear();
					console.log(error);
				});
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/css/index.scss';
// 改写部分vant  Dialog弹出框
.van-dialog {
	width: px2rem(640);
	font-size: px2rem(32);
}
.van-dialog__message {
	padding: px2rem(52) px2rem(48);
	font-size: px2rem(28);
	line-height: px2rem(40);
}
.van-dialog__header {
	padding-top: px2rem(52);
	line-height: px2rem(58);
}
.van-dialog__cancel,
.van-dialog__confirm {
	height: px2rem(96);
}
.van-button {
	font-size: px2rem(32);
}
.van-hairline--top::after {
	border-top-width: px2rem(4);
}
.van-hairline--left::after {
	border-left-width: px2rem(4);
}
.van-picker__toolbar{
	height: px2rem(100) !important;
}
.van-loading__spinner {
	width: px2rem(100) !important;
	height: px2rem(100) !important;
}
.van-toast {
	width: auto;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	background: #fafafa;
	padding-top: px2rem(80);
	box-sizing: border-box;

	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #000000;

		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.wrapper_centent {
		width: 100%;
		padding: px2rem(32);
		box-sizing: border-box;
		
		.box{
			width: 100%;
			background: #FFFFFF;
			box-shadow: 0px px2rem(4) px2rem(50) 0px rgba(0,0,0,0.05);
			border-radius: px2rem(16);
			padding: px2rem(32);
			box-sizing: border-box;
			
			.titleValue{
				font-size: px2rem(36);
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				line-height: px2rem(58);
				padding-bottom: px2rem(32);
				box-sizing: border-box;
				text-align: left;
				border-bottom: px2rem(2) solid #F2F2F2;
			}
			
			.textcent{
				margin-top: px2rem(32);
				width: 100%;
				height: px2rem(400);
				position: relative;
				
				textarea{
					width: 100%;
					height: 100%;
					border: none;
					padding: px2rem(24);
					box-sizing: border-box; 
					background: #FAFAFA;
					border-radius: px2rem(4);
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400; 
					line-height: px2rem(44);
					resize:none;
				}
			
				span{
					position: absolute;
					bottom:5%;
					right:3%;
					color:#969799;
					font-size: px2rem(28);
				}
			}
			.uls{
				width: 100%;
				margin-top: px2rem(32);
				line-height: px2rem(44);
				
				.lis{
					width: 100%; 
					text-align: left;
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
				}
			}
		}
	}
	
	
	.fromBut {
		width: calc(100% - #{px2rem(32)} - #{px2rem(32)});
		position: fixed;
		bottom: 3%;
		left: 0;
		right: 0;
		padding-left: px2rem(32);
		padding-right: px2rem(32);
		box-sizing: border-box;
		height: px2rem(88);
		line-height: px2rem(88);
		background: #122ab0;
		border-radius: px2rem(92);
		margin: auto;
		font-size: px2rem(32);
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #ffffff;
		box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(18, 42, 176, 0.3);
	}
}
</style>
